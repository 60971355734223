import logo from './static/laconia-logo-white.png';

import './App.css';

function App() {
    return (
        <div className="App">
      <header className="App-header">
	    <video id="background-video" loop muted autoPlay poster="" class="background-video" id="background-video">
    <source src="https://s3.amazonaws.com/www.laconia.ai/static/laconia_catamaran.mp4" type="video/mp4" />
	    </video>
     <section class="hero">
        <div class="hero-inner">
        </div>
    </section>
	    </header>
    </div>
  );
}

export default App;
